<template>
  <div :class="['variant-wrapper opt-button', { selected: isSelected }, { disabled: isDisabled }]">
    <button
      :class="['option pointer flex-col clear-btn-style', { 'has-option-desc': option.optionDesc }]"
      :aria-label="`Set Product Option ${getOptionValue(option)}`"
      :disabled="isDisabled"
      @click.prevent="emit('set-child', index)"
    >
      <!-- click prevent stops product card navigation to pdp when selecting variants -->
      {{ getOptionValue(option) }}
      <span v-if="option.optionDesc" class="opt-desc">{{ option.optionDesc }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  option: {
    type: Object as () => any,
    required: true,
  },
  isSelected: {
    type: Boolean,
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  selectedOption: {
    type: Object as () => any,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  isPdp: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits<{ (event: 'set-child', i: number): void }>();

const getOptionValue = (option: any): any | undefined => {
  return option?.label;
};
</script>

<style lang="scss" scoped>
.opt-button {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  &.disabled {
    border: 2px solid $color-neutral-cool-300 !important;
    position: relative;
    background: rgb(247, 247, 247) !important;
    color: $color-neutral-cool-300 !important;
    button {
      cursor: not-allowed;
    }
  }
  button {
    width: 100%;
    align-items: center;
    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
  &:focus-visible,
  &:focus {
    outline: none;
  }
}
.opt-desc {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: normal;
}
</style>
